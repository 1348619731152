import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import CodeExample from "../components/home/CodeExample"
import { LandingPageFeatures } from "../components/landingPage/LandingPageFeatures"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
}))

const PHPTechnologyPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)
  // const [isSecondTranslated, setSecondTranslated] = useState(false);
  const image = data

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Translate PHP-Arrays, JSON or PO-Files"
          subtitle="Works with Laravel, Sympfony, Yii & Wordpress"
          source="Signup HeroMarkdown"
        />
      </HeroSection>

      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Translate PO-Files, JSON and PHP Arrays directly with the online
              translator.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Use Simpleen to translate your WebApp, Theme, Plugin. <br />
              <br />
              <br />
              Simply copy/paste your files into the Web Translator and receive
              instant results. Simpleen manages the correct handling of the
              variable interpolation and the provided format.
            </Typography>
            <Typography variant="h2" className={classes.h2}>
              Laravel
            </Typography>
            <Typography variant="body1">
              Laravel can be localized with PHP- or JSON files. The variables
              are marked with a prefixed colon, i.e. :name. These variables will
              not get translated, but still be provided for placements in the
              translations.
            </Typography>
            <Typography variant="h2" className={classes.h2}>
              Wordpress
            </Typography>
            <Typography variant="body1">
              Wordpress works with PO-Files which are created by multiple
              plugins and themes. If you like to translate a whole project, we
              recommend using the Simpleen CLI to find all PO-Files and
              translate them automatically. You can also translate PO-Files
              directly with the online translator. The variables are provided
              with %s and %d in the translatio, i.e. My name is %s.
            </Typography>
            <Typography variant="h2" className={classes.h2}>
              Zend / Laminas
            </Typography>
            <Typography variant="body1">
              The translations are structured in INI/Properties-files or PHP
              Arrays. Variable interpolation are discussed including the usage
              of percentage.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} alignContent="space-between">
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <GatsbyImage
                image={data.php.childImageSharp.gatsbyImageData}
                alt="Laravel Logo"
              />
            </Typography>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="de"
              isTranslated={isFirstTranslated}
            />
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate Example"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CompatibleLibraries currentPage="php" />

      <LandingPageFeatures source="Signup ButtonMarkdown" />

      <Seo
        general={{
          title: "Translate PHP-i18n projects",
          description:
            "Localize your i18n PHP-projects created with laravel, yii and wordpress",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const docuLPQuery = graphql`
  query PHPLPQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    php: file(relativePath: { eq: "logos/laravel.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          quality: 100
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    snippetExample: markdownRemark(frontmatter: { slug: { eq: "/phpCode" } }) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/phpCodeTranslated" } }
    ) {
      html
    }
  }
`

export default PHPTechnologyPage
